import transformer from '@sholdi/shared/lib/transformers/product'

const transformProduct = product => {
  // Guard clause for null/undefined
  if (!product) {
    throw new Error('Product cannot be null or undefined')
  }

  // Check if it's a valid object
  if (typeof product !== 'object') {
    throw new Error('Product must be an object')
  }

  try {
    return transformer(product)
  } catch (error) {
    throw new Error(`Failed to transform product: ${JSON.stringify(error)}`)
  }
}

export default transformProduct
