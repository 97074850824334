import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";

import useQueryHook from "@sholdi/shared/lib/urql/useQueryHook";

import PRODUCT_COMBINATION_PRICE_QUERY from "@sholdi/graphql/queries/products/productCombinationPrice";

import { SkeletonLine } from "@sholdi/primitives/atoms/Skeleton";
import Flex from "@sholdi/primitives/atoms/Flex";
import Text from "@sholdi/primitives/atoms/Text";
import Link from "@sholdi/primitives/atoms/Link";
import Price from "@sholdi/primitives/atoms/Price";

const ProductCardPricing = ({
  combination,
  productId,
  onMatomoPush,
  href,
  showPrice = true,
  className = "",
  ...props
}) => {
  const Component = href ? Link : Text;

  const { data, loading } = useQueryHook(PRODUCT_COMBINATION_PRICE_QUERY, {
    variables: {
      where: {
        product: {
          catalogItemId: { _eq: productId, _isNull: false },
        },
        availableStock: { _gt: 0 },
      },
      orderBy: { price: "ASC" },
      limit: 1,
    },
  });

  const { productCombinations = [] } = data || {};

  const singleCombination = productCombinations[0] ?? combination;

  if (loading) {
    return <SkeletonLine className="w-36 h-6" animation="wave" />;
  }

  if (singleCombination) {
    const { salePrice, price } = singleCombination;
    const hasSalePrice = !!(
      salePrice &&
      salePrice !== "0" &&
      salePrice !== price &&
      salePrice !== "" &&
      salePrice < price
    );
    const hasPrice = !!(price && price !== "0");

    return (
      <Flex
        className={twMerge(
          "flex-col items-center flex-wrap justify-center",
          className,
        )}
        {...props}
      >
        {showPrice && hasSalePrice && productCombinations.length > 0 && (
          <Component
            className={`font-normal font-heading text-center text-primary cursor-pointer hover:no-underline ${href ? "hover:underline" : ""}`}
            {...(href ? { href, onClick: onMatomoPush } : {})}
          >
            <Text className="text-sm md:text-xs lg:text-xs font-normal text-gray-600 font-heading line-through leading-none">
              <Price price={price} />
            </Text>
          </Component>
        )}
        {(hasSalePrice || hasPrice) && showPrice && (
          <Component
            className={`font-normal font-heading text-center text-primary-text cursor-pointer hover:no-underline ${href ? "hover:underline" : ""}`}
            {...(href ? { href, onClick: onMatomoPush } : {})}
          >
            <Text
              className={`block text-sm font-heading font-bold leading-relaxed ${hasSalePrice ? "text-success-main" : "text-primary"}`}
            >
              <Price price={hasSalePrice ? salePrice : price} />
            </Text>
          </Component>
        )}
      </Flex>
    );
  }

  return null;
};

ProductCardPricing.displayName = "ProductCardPricing";

ProductCardPricing.propTypes = {
  href: PropTypes.string,
  combination: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  productId: PropTypes.string,
  showPrice: PropTypes.bool,
  hidePrice: PropTypes.bool,
  onMatomoPush: PropTypes.func,
  className: PropTypes.string,
};

export default ProductCardPricing;
