const PRODUCT_COMBINATION_PRICE_QUERY = /* GraphQL */ `
  query ProductCombinationPriceQuery(
    $distinctOn: [ProductCombinationsSelectColumn!]
    $limit: Int
    $offset: Int
    $orderBy: [ProductCombinationsOrderBy!]
    $where: ProductCombinationsBoolExp
  ) {
    productCombinations(
      distinctOn: $distinctOn
      where: $where
      offset: $offset
      limit: $limit
      orderBy: $orderBy
    ) {
      id
      __typename
      productId
      price
      salePrice
    }
  }
`;

export default PRODUCT_COMBINATION_PRICE_QUERY;
