import PropTypes from "prop-types";
import { twMerge } from "tailwind-merge";
import { cva } from "class-variance-authority";

import getVariantChildStyles from "@sholdi/shared/helpers/getVariantChildStyles";

import Link from "@sholdi/primitives/atoms/Link";

import ProductImage from "../ProductImage";
import ProductCardPricing from "../ProductCardPricing";

const variantContentButtonClasses = {
  squashed: "absolute left-1/2 -top-8 transform -translate-x-1/2",
};

const variants = {
  squashed: {
    wrapper: "p-0 overflow-hidden relative pb-6",
    header: "absolute top-0 left-0 z-[2] pr-1",
    // image: { height: "180px" },
    content: "relative px-4 pt-4 pb-2 mt-0",
    footer: "px-4 justify-center",
  },
};

const wrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "wrapper"),
  },
});

export const ProductCardWrapper = ({ variant, className, ...props }) => (
  <div className={twMerge(wrapper({ variant }), className)} {...props} />
);

ProductCardWrapper.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const imageContainer = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "imageContainer"),
  },
});

export const ProductCardImageContainer = ({ variant, className, ...props }) => (
  <div className={twMerge(imageContainer({ variant }), className)} {...props} />
);

ProductCardImageContainer.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const cardInfo = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "info"),
  },
});

export const ProductCardInfo = ({ variant, className, ...props }) => (
  <div className={twMerge(cardInfo({ variant }), className)} {...props} />
);

ProductCardInfo.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const pricing = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "pricing"),
  },
});

export const Pricing = ({ variant, className, ...props }) => (
  <ProductCardPricing
    className={twMerge(pricing({ variant }), className)}
    {...props}
  />
);

Pricing.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const content = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "content"),
  },
});

export const ProductCardContent = ({ variant, className, ...props }) => (
  <div
    className={twMerge(
      content({ variant }),
      variantContentButtonClasses[variant] ?? "",
      className,
    )}
    {...props}
  />
);

ProductCardContent.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const infoWrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "basicInfoWrapper"),
  },
});

export const InfoWrapper = ({ variant, className, ...props }) => (
  <div className={twMerge(infoWrapper({ variant }), className)} {...props} />
);

InfoWrapper.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const actionsWrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "actionsWrapper"),
  },
});

export const ActionsWrapper = ({ variant, className, ...props }) => (
  <div className={twMerge(actionsWrapper({ variant }), className)} {...props} />
);

ActionsWrapper.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const priceWrapper = cva("flex", {
  variants: {
    variant: getVariantChildStyles(variants, "priceWrapper"),
  },
});

export const PriceWrapper = ({ variant, className, ...props }) => (
  <div className={twMerge(priceWrapper({ variant }), className)} {...props} />
);

PriceWrapper.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const cardImage = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "image"),
  },
});

export const ProductCardImage = ({ variant, className, ...props }) => (
  <ProductImage
    className={twMerge(cardImage({ variant }), className)}
    {...props}
  />
);

ProductCardImage.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};

const titleLink = cva("", {
  variants: {
    variant: getVariantChildStyles(variants, "title"),
  },
});

export const ProductCardLinkTitle = ({ variant, className, ...props }) => (
  <Link className={twMerge(titleLink({ variant }), className)} {...props} />
);

ProductCardLinkTitle.propTypes = {
  variant: PropTypes.oneOf(Object.keys(variants)),
  className: PropTypes.string,
};
