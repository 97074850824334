import { memo, useMemo } from "react";
import PropTypes from "prop-types";

import Image from "@sholdi/primitives/atoms/Image";

const ProductImage = ({ product, alt, ...props }) => {
  const image = useMemo(() => {
    if (props?.src) {
      return props.src;
    }
    if (product?.variation_image) {
      return product.variation_image;
    }
    if (product?.productImage) {
      return product.productImage.path;
    }
    if (product?.productImages?.length) {
      return product.productImages[0].path;
    }
    return null;
  }, [
    props.src,
    product?.productImage,
    product?.productImages,
    product?.variation_image,
  ]);

  return (
    <Image
      src={image}
      alt={
        alt ??
        product?.title ??
        product?.productDescriptions?.[0]?.name ??
        "product title"
      }
      {...props}
    />
  );
};

ProductImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  product: PropTypes.shape({
    title: PropTypes.string,
    productDescriptions: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      }),
    ),
    variation_image: PropTypes.string,
    productImages: PropTypes.arrayOf(
      PropTypes.shape({
        path: PropTypes.string,
      }),
    ),
    productImage: PropTypes.shape({
      path: PropTypes.string,
    }),
  }),
};

export default memo(ProductImage);
