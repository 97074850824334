import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { twMerge } from 'tailwind-merge'

import ProductCard from '../../molecules/ProductCard'
import SkeletonProductList from '../../molecules/SkeletonProductList'

const ProductList = ({
  products = [],
  header,
  CardComponent = ProductCard,
  cardVariant,
  containerProps,
  loading,
  showLoadingSkeleton,
  priority,
  getIdFrom = 'id',
  columns: columnsProp,
  gap,
  skeletonProductsNumber = 12,
  variant = 'none',
  view = 'grid',
  getKeyFrom = 'id',
  className,
  hrefPrefix,
  ...props
}) => {
  //
  const columnClass = useMemo(
    () =>
      variant === 'twoColumnList'
        ? 'grid-cols-1 md:grid-cols-2'
        : 'grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6',
    [variant],
  )

  const isList = view === 'list'

  if (loading && showLoadingSkeleton) {
    return (
      <SkeletonProductList
        items={skeletonProductsNumber}
        className={gap}
        productHeight="400px"
        productWidth="full"
      />
    )
  }

  if (products && Array.isArray(products) && products.length) {
    return (
      <div
        className={twMerge('w-full', props?.className, containerProps?.className)}
        // {...getWidth(props)}
        {...containerProps}
        // causes modal backdrop not taking full width & height
        // style={{ contentVisibility: 'auto' }}
      >
        {header}
        <div
          className={twMerge(
            'self-start gap-4 lg:gap-8 xl:gap-2 2xl:gap-4',
            ...(!isList
              ? ['grid', columnClass, columnsProp, gap]
              : ['flex flex-col flex-wrap']),
            className,
          )}
          {...props}
        >
          {products.map((product, index) => (
            <CardComponent
              key={product?.[getKeyFrom] || index}
              hrefPrefix={hrefPrefix}
              variant={cardVariant}
              product={product}
              getIdFrom={getIdFrom}
              priority={priority}
            />
          ))}
        </div>
      </div>
    )
  }
  return null
}

ProductList.propTypes = {
  products: PropTypes.arrayOf(PropTypes.shape({})),
  view: PropTypes.string,
  header: PropTypes.node,
  CardComponent: PropTypes.elementType,
  variant: PropTypes.string,
  cardVariant: PropTypes.oneOf(['none', 'squashed', 'simple', 'inlineSimple']),
  containerProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  loading: PropTypes.bool,
  showLoadingSkeleton: PropTypes.bool,
  skeletonProductsNumber: PropTypes.number,
  priority: PropTypes.bool,
  getIdFrom: PropTypes.string,
  getKeyFrom: PropTypes.string,
  className: PropTypes.string,
  columns: PropTypes.string,
  gap: PropTypes.string,
  hrefPrefix: PropTypes.string,
}

ProductList.displayName = 'ProductList'

export default ProductList
