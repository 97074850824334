import { useMemo } from 'react'
import { formatPrice } from '@sholdi/shared/helpers/formatPrice'

const Price = ({ price, currency = 'BAM', locale = 'hr', inCents = true }) => {
  const formattedPrice = useMemo(
    () =>
      new Intl.NumberFormat(locale, { style: 'currency', currency }).format(
        inCents ? formatPrice(price) : price,
      ),
    [price, currency, locale, inCents],
  )

  return formattedPrice
}

export default Price
