import { useMemo, memo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'

import useTranslation from '@sholdi/hooks/useTranslation'
import { PRODUCT_REVIEWS_TYPE } from '@sholdi/shared/constants/customerReviews'
import { onProductViewPush } from '@sholdi/shared/lib/matomo/utils'

import ShoppingCart from '@sholdi/icons/ShoppingCart'
import Text from '@sholdi/primitives/atoms/Text'
import Chip from '@sholdi/primitives/atoms/Chip'
import Link from '@sholdi/primitives/atoms/Link'

import RecordRating from '../RecordRating'
import ProductCardActionsActivator from './ProductCardActionsActivator'
import {
  Pricing,
  ProductCardContent,
  ProductCardImage,
  ProductCardImageContainer,
  ProductCardInfo,
  ProductCardLinkTitle,
  ProductCardWrapper,
} from './components'
import TagList from './TagList'
import transformProduct from './utils'

const AddToCartButton = dynamic(() =>
  import('../AddToCartButton').then(mod => mod.default),
)

const SIZE = 237

const ProductCard = forwardRef(
  (
    {
      product = {},
      variant = 'none',
      priority = false,
      getIdFrom = 'id',
      className = '',
      // eslint-disable-next-line no-unused-vars
      hrefPrefix = '',
      disabled = false,
      ...props
    },
    ref,
  ) => {
    const { t } = useTranslation()
    const router = useRouter()

    const transformedProduct = useMemo(
      () => transformProduct(product, getIdFrom),
      [product, getIdFrom],
    )

    const {
      id,
      name,
      slug,
      mainImage,
      variation_image,
      featured_image,
      tags,
      combinationId,
      price,
      salePrice,
      availableStock,
      shopId,
      shopName,
      ucis,
      isExternal,
      externalLink,
      localeKey,
    } = transformedProduct

    const isSquashedVariant = variant === 'squashed'

    const productHref = useMemo(() => `/products/${id}/${slug}`, [id, slug])

    const onClick = () => onProductViewPush(product)

    // for cart button
    const productCombination = useMemo(() => {
      if (!combinationId && !product.product_id) return null

      return {
        price,
        salePrice,
        availableStock,
        id: combinationId,
        ucis,
        isExternal,
        externalLink,
      }
    }, [
      combinationId,
      product.product_id,
      price,
      salePrice,
      availableStock,
      ucis,
      isExternal,
      externalLink,
    ])

    const shouldRender = id && name

    if (!shouldRender) {
      return null
    }

    return (
      <ProductCardWrapper
        ref={ref}
        variant={variant}
        className={twMerge(
          'w-full flex-col overflow-hidden relative h-auto rounded bg-white hover:no-underline',
          className,
          disabled ? 'pointer-events-none' : '',
        )}
        {...props}
      >
        <ProductCardImageContainer
          variant={variant}
          className="flex-col items-center w-full relative mb-2"
        >
          {tags?.some(tag => tag.toLowerCase() === 'hitno') && (
            <Chip
              radius={isSquashedVariant ? 'diagonal' : null}
              variant="sm"
              className={twMerge(
                'absolute top-0 left-0 z-20 bg-error-main text-white',
                isSquashedVariant && '-mt-0.5',
              )}
            >
              {t('products.urgent')}
            </Chip>
          )}
          <ProductCardActionsActivator
            product={{ ...product, id: product[getIdFrom] }}
            className={disabled ? 'pointer-events-none' : ''}
          />
          <Link href={productHref} onClick={onClick}>
            <ProductCardImage
              src={variation_image ?? featured_image ?? mainImage}
              alt={name}
              variant={variant}
              priority={priority}
              className="object-contain size-[237px]"
              width={SIZE}
              height={SIZE}
            />
          </Link>
        </ProductCardImageContainer>
        <ProductCardContent
          variant={variant}
          className="flex-col items-center justify-between overflow-hidden relative mt-2"
        >
          {productCombination && (
            <AddToCartButton
              product={{
                ...product,
                productDescriptions: [
                  {
                    name,
                    localeKey,
                    slug,
                  },
                ],
                shop: {
                  name: shopName,
                  id: shopId,
                },
                productCombination,
              }}
              combination={productCombination}
              variant="primary"
              buttonSize="xs"
              icon={isSquashedVariant ? ShoppingCart : null}
              className={twMerge(
                'font-medium mx-auto mt-2',
                disabled ? 'pointer-events-none' : '',
              )}
            >
              {t('products.toCart')}
            </AddToCartButton>
          )}
          <ProductCardInfo variant={variant} className="items-center flex-col mt-2">
            <Text as="h3" className="overflow-hidden h-12 leading-6 text-center">
              <ProductCardLinkTitle
                href={productHref}
                onClick={onClick}
                variant={variant}
                className="leading-6 text-sm font-normal text-primary-text font-heading pt-1 z-[4]"
              >
                {name}
              </ProductCardLinkTitle>
            </Text>
            <div className="flex w-full justify-center">
              <RecordRating
                width="max"
                modelId={product[getIdFrom]}
                type={PRODUCT_REVIEWS_TYPE}
                size="xs"
                readOnly
                onClick={() => router.push(productHref)}
                className="my-2 cursor-pointer text-black"
              />
            </div>
            {productCombination && (
              <Pricing
                combination={productCombination}
                variant={variant}
                productId={id}
                href={productHref}
                onMatomoPush={onClick}
              />
            )}
          </ProductCardInfo>
          {!!tags?.length && <TagList tags={tags} variant={variant} />}
        </ProductCardContent>
      </ProductCardWrapper>
    )
  },
)

ProductCard.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    slug: PropTypes.string,
    mainImage: PropTypes.string,
    variation_image: PropTypes.string,
    featured_image: PropTypes.string,
    tags: PropTypes.array,
    combinationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    salePrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    availableStock: PropTypes.number,
    shopId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    shopName: PropTypes.string,
    ucis: PropTypes.string,
    isExternal: PropTypes.bool,
    externalLink: PropTypes.string,
    localeKey: PropTypes.string,
  }),
  variant: PropTypes.oneOf(['none', 'squashed']),
  priority: PropTypes.bool,
  getIdFrom: PropTypes.string,
  className: PropTypes.string,
  hrefPrefix: PropTypes.string,
  disabled: PropTypes.bool,
}

ProductCard.displayName = 'ProductCard'

export default memo(ProductCard)
