import PropTypes from "prop-types";

import Chip from "@sholdi/primitives/atoms/Chip";
import Text from "@sholdi/primitives/atoms/Text";

const TagList = ({ tags = [], variant }) => (
  <div variant={variant} className="flex flex-wrap px-0 mt-2">
    {tags
      .filter((tag) => tag.toLowerCase() !== "hitno")
      .map((tag) => (
        <Chip key={tag} className="p-1 mr-1 mb-1 rounded">
          {/*
    TODO : Add icon, define what icon to show
  <Truck /> */}
          <Text className="text-xs text-inherit mx-1">{tag}</Text>
        </Chip>
      ))}
  </div>
);

TagList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  variant: PropTypes.string,
};

export default TagList;
